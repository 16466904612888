/**判断移动端还是pc */
export const isMobile = () => {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}

/**
 * 数字不够x位，前面补0
 * @param {*} num 数字
 * @param {*} len 补零长度
 * @returns  0001
 */
export function formatZero(num, len) {
    if (String(num).length > len) {
        return num;
    }
    return (Array(len).join(0) + num).slice(-len)
}