import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { isMobile, formatZero } from '@/utils/utils.js';
// 分页组件
import Pagination from "@/components/Pagination";
import moment from 'moment';
import { Loading, Toast  } from 'vant';
// 全局方法挂载
Vue.prototype.isMobile = isMobile;
Vue.prototype.formatZero = formatZero;
Vue.prototype.$moment = moment;
//汉化，否则显示日期格式是国外的日期格式
moment.locale("zh-CN");

// 全局组件挂载
Vue.component('Pagination', Pagination)

Vue.use(ElementUI);
Vue.use(Loading);
Vue.use(Toast);

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
