import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index.vue'
import Trend from '@/views/Trend.vue'
import NetworkNode from '@/views/NetworkNode.vue'
import Address from '@/views/Address.vue'
import HexDetail from '@/views/HexDetail.vue'
import Search from '@/views/Search.vue'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  {
    path:'',
    redirect:'/index'
  },
  {
    path: '/index',
    name: 'Index',
    component: Index,
  },
  {
    path: '/trend',
    name: 'Trend',
    component: Trend
  },
  {
    path: '/networkNode',
    name: 'NetworkNode',
    component: NetworkNode
  },
  {
    path: '/address',
    name: 'Address',
    component: Address
  },
  {
    path: '/hexDetail',
    name: 'HexDetail',
    component: HexDetail
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/chain/index',
    name: 'Chain',
    component: () => import('../views/chain/index.vue')
  },
  {
    path: '/chain/block',
    name: 'Block',
    component: () => import('../views/chain/block.vue')
  },
  {
    path: '/chain/data',
    name: 'Data',
    component: () => import('../views/chain/data.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
