import requests from "./axios"; //引入二次封装的axios

// 文化数据汇总统计
export const summaryData = (params) => requests({
    url: '/blockChainBase/statistics',
    method: 'get',
    params
});

// 持有人数变化
export const holderNum = (params) => requests({
    url: '/blockChainBase/holder',
    method: 'get',
    params
});

// 文化数据最新交易
export const listData = (params) => requests({
    url: '/blockChainBase/list',
    method: 'get',
    params
});

// 钱包地址详情
export const addrDetail = (params) => requests({
    url: '/blockChainBase/addrDetail',
    method: 'get',
    params
});

// 网络节点数量统计
export const getNoodeNum = (params) => requests({
    url: '/node/noodeNum',
    method: 'get',
    params
}); 

// 网络节点状态数量
export const getNoodeInfoStatus = (params) => requests({
    url: '/node/noodeInfoStatus',
    method: 'get',
    params
}); 

// 网络节点分页
export const networkNode = (params) => requests({
    url: '/node/noodeInfo',
    method: 'get',
    params
});

// 交易hash详情
export const blockHashDetail = (hash) => requests({
    url: `/blockChainBase/blockHashDetail/${hash}`,
    method: 'get',
});

// 每日交易金额
export const turnoverEveryDay = (params) => requests({
    url: `/blockChainBase/turnoverEveryDay`,
    method: 'get',
    params
});

// 判断搜索类型
export const searchAddrHash = (params) => requests({
    url: `/blockChainBase/searchAddrHash`,
    method: 'get',
    params
});


